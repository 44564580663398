





























































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import DatetimePicker from "vue-persian-datetime-picker";
import { CallApi } from "@/networks/CallApi";
import Office from "../../components/inputs/office.vue";

@Component({
  components: {
    DatetimePicker,
    office: Office
  }
})
export default class Quick extends Vue {
  private data = [];
  private form: Record<string, any> = {
    q: "",
    office: ""
  };
  private page = 1;
  private roles = localStorage.getItem("roles") || [];

  mounted() {
    this.form = {
      ...this.form,
      ...this.$route.query
    };
    this.calls();
  }

  public async calls(): Promise<void> {
    try {
      const res: AxiosResponse = await new CallApi().list({
        ...this.form,
        page: this.page
      });
      this.data = res.data.data;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  private _timerId = 0;
  fetchEntriesDebounced(): void {
    // cancel pending call
    clearTimeout(this._timerId);

    // delay new call 500ms
    this._timerId = window.setTimeout(() => {
      this.page = 1;
      this.calls();
    }, 700);
  }

  @Watch("form.q")
  public onSearchChanged() {
    this.fetchEntriesDebounced();
  }

  @Watch("form.office")
  public onSearchOffice() {
    this.calls();
  }

  @Watch("page")
  public onPageChanged() {
    this.calls();
  }
}
