import Api from "./api";
import { AxiosResponse } from "axios";

export default class GeneralApi extends Api {
  private token = "";
  constructor() {
    super();
    this.token = localStorage.getItem("token") ?? "";
  }

  private getFormData = (object: Record<string, any>) =>
    Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());

  public dashboard(params: Record<string, string>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/dashboard", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params
    });
  }

  public financialDashboard(params: Record<string, string>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/financial", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params
    });
  }

  public testPanel(params: Record<string, string>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/testPanel", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params
    });
  }

  public countries(params: Record<string, string>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/countries", {
      params
    });
  }

  public city(params: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/city", {
      params
    });
  }

  public province(params: Record<string, string>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/province", {
      params
    });
  }

  public getAdmin(): Promise<AxiosResponse> {
    return this.get<ServerResponse>("get-admin", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public download(
    id: string,
    params: Record<string, string>
  ): Promise<AxiosResponse> {
    return this.get<ServerResponse>("download/" + id, {
      params,
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public notification(params: Record<string, string>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/notification-manager", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params
    });
  }

  public showNotification(id: number): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/notification-manager/" + id, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public storeNotification(
    data: Record<string, any>,
    id: number
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>("/notification-manager/" + id, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public setReminder(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("/reminder", data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public deleteReminder(
    data: Record<string, any>,
    id: string
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>(`/reminder/${id}/delete`, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public updateReminder(
    data: Record<string, any>,
    id: string
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>(`/reminder/${id}`, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public sendCustomMessage(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("/notification-manager/custom", data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public office(params: Record<string, string>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/offices", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params
    });
  }
  public getOffice(id: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/offices/" + id, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }
  public editOffice(
    data: Record<string, any>,
    id: string
  ): Promise<AxiosResponse> {
    return this.post<ServerResponse>("/offices/" + id, data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }
  public storeOffice(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("/offices", data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }
}
