var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v("تماس ها")]),(!_vm.roles.includes('user'))?_c('form',[_c('v-row',[_c('v-col',{staticClass:"px-12",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":"اینجا جستجو کنید... "},model:{value:(_vm.form.q),callback:function ($$v) {_vm.$set(_vm.form, "q", $$v)},expression:"form.q"}})],1),_c('v-col',{staticClass:"px-12",attrs:{"cols":"12","md":"6"}},[_c('office',{attrs:{"physical":"1","defaultValue":_vm.form.office},on:{"change-value":function($event){_vm.form.office = $event}}})],1)],1)],1):_vm._e(),_c('v-simple-table',{attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-right"},[_vm._v("شناسه")]),_c('th',{staticClass:"text-right"},[_vm._v("مشتری")]),_c('th',{staticClass:"text-right"},[_vm._v("پاسخگو")]),_c('th',{staticClass:"text-right"},[_vm._v("زمان تماس")]),_c('th',{staticClass:"text-right"},[_vm._v("تاریخ تماس")]),(!_vm.roles.includes('user'))?_c('th',{staticClass:"text-right"},[_vm._v(" فایل صوتی ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.data.data),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[(item.user)?_c('a',{attrs:{"href":'/calls?q=&type=all&userId=' + item.user.id,"title":'برای مشاهده کلیه رویدادهای ' +
                    item.user.fname +
                    ' ' +
                    item.user.lname +
                    ' کلیک کنید. '}},[_vm._v(_vm._s(item.user.fname + " " + item.user.lname))]):_c('span',[_vm._v(_vm._s(item.from))])]),_c('td',[(item.mentor)?_c('a',{attrs:{"href":'/calls?q=&type=all&mentor_id[]=' + item.mentor.id,"title":'برای مشاهده کلیه تماسهای ' +
                    item.mentor.fname +
                    ' ' +
                    item.mentor.lname +
                    ' کلیک کنید. '}},[_vm._v(_vm._s(item.mentor.fname + " " + item.mentor.lname))]):_c('span',[_vm._v(_vm._s(item.to))])]),_c('td',[_vm._v(_vm._s(_vm._f("friendly_duration")(item.duration)))]),_c('td',[_vm._v(_vm._s(_vm._f("toDateTime")(item.called_at)))]),(!_vm.roles.includes('user'))?_c('td',[(item.audio)?_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":item.audio,"type":"audio/wav"}}),_vm._v(" Your browser does not support the audio tag. ")]):_vm._e()]):_vm._e()])}),0)]},proxy:true}])}),(_vm.data.last_page)?_c('v-pagination',{attrs:{"length":_vm.data.last_page,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }