











import { Vue, Watch, Prop, Component } from "vue-property-decorator";
import GeneralApi from "@/networks/GeneralApi";
import store from "../../../store";
import { AxiosResponse } from "axios";
import { VSelect } from "vuetify/lib";
import { debounce } from "vue-debounce";

@Component({
  components: {
    VSelect
  }
})
export default class Office extends Vue {
  @Prop({})
  defaultValue: number | undefined;
  @Prop()
  physical: any;
  @Prop()
  removeNone: any;
  @Prop({ default: "دفتر" })
  label: any;
  private offices: Array<Record<string, unknown>> = [];
  private officeId: number | undefined = 0;

  mounted() {
    this.office();
    if (this.defaultValue !== undefined) {
      this.officeId = this.defaultValue;
    }
  }

  public async office(): Promise<void> {
    try {
      const res: AxiosResponse = await new GeneralApi().office({
        physical: this.physical
      });
      if (this.defaultValue == 0) {
        if (!this.removeNone) {
          this.offices = [{ name: "هیچکدام", id: 0 }].concat(res.data.data);
        } else {
          this.offices = res.data.data;
        }
      } else {
        this.offices = res.data.data;
      }
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("officeId")
  public onPageChanged() {
    this.$emit("change-value", this.officeId);
  }

  @Watch("physical")
  public onPhysicalChanged() {
    debounce(() => this.office(), "500ms")();
  }

  @Watch("defaultValue")
  public onDefaultValueChanged(val: number) {
    this.officeId = val;
  }
}
