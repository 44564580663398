import Api from "./api";
import { AxiosError, AxiosResponse } from "axios";

export class CallApi extends Api {
  private token = "";
  constructor() {
    super();
    this.token = localStorage.getItem("token") || "";
  }

  public list(params: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/calls", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params
    });
  }
}
